@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
.t-white {
  color: #ffffff !important;
}

.f-500 {
  font-weight: 500;
}

.f12 {
  font-size: 12px;
  line-height: 16px;
}

.f13 {
  font-size: 13px;
  line-height: 17px;
}

.f14 {
  font-size: 14px;
  line-height: 18px;
}
@media screen and (max-width: 1040px) {
  .f14 {
    font-size: 12px;
    line-height: 16px;
  }
}

.f16 {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.9975px;
}
@media screen and (max-width: 1040px) {
  .f16 {
    font-size: 14px;
    line-height: 18px;
  }
}

.f18 {
  font-size: 18px;
  line-height: 22px;
}
@media screen and (max-width: 1040px) {
  .f18 {
    font-size: 16px;
    line-height: 21px;
  }
}

.f24 {
  font-size: 24px;
  line-height: 31px;
}
@media screen and (max-width: 1040px) {
  .f24 {
    font-size: 20px;
    line-height: 26px;
  }
}

.f26 {
  font-size: 26px;
  line-height: 34px;
}
@media screen and (max-width: 1040px) {
  .f26 {
    font-size: 22px;
    line-height: 29px;
  }
}

.f30 {
  font-size: 30px;
  line-height: 39px;
}
@media screen and (max-width: 1040px) {
  .f30 {
    font-size: 22px;
    line-height: 29px;
  }
}

.f40 {
  font-size: 40px;
  line-height: 52px;
}
@media screen and (max-width: 1040px) {
  .f40 {
    font-size: 22px;
    line-height: 29px;
  }
}

.f-400 {
  font-weight: 400;
}

.f-500 {
  font-weight: 500;
}

*,
*:after,
*:before {
  letter-spacing: 0.9975px;
  box-sizing: border-box;
}

html {
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

svg {
  overflow: visible;
}

body {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: #000;
  color: #ffffff;
  margin: 0;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  cursor: default;
}
body * {
  font-weight: 400;
  font-family: "DM Sans", sans-serif !important;
}
body > div:not([data-popper-placement=bottom]) > div {
  min-height: 100vh;
  overflow-x: hidden;
}
body *[mouse=pointer] {
  cursor: pointer;
}
body *[mouse=none] {
  cursor: default;
}
body .page_title {
  position: fixed;
  top: 42px;
  left: 100%;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 94px;
  line-height: 122px;
  letter-spacing: 2.3438px;
  transform-origin: top left;
  transform: rotate(90deg) translateY(17.82px);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 2;
  white-space: nowrap;
  padding: 0 34px 0 0;
}
body .page_title > * {
  font-weight: 500 !important;
}
@media screen and (max-width: 1040px) {
  body .page_title {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 100%;
    letter-spacing: 2.3438px;
    margin: 0 0 40px 0;
    text-align: center;
  }
}
body input::-webkit-outer-spin-button,
body input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
body input[type=number] {
  -moz-appearance: textfield;
}
body .tick_checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
body .tick_checkbox input[type=checkbox] {
  display: none;
}
body .tick_checkbox input[type=checkbox]:checked ~ label > div::after {
  transform: scale(1);
  opacity: 1;
}
body .tick_checkbox input[type=checkbox]:checked[cust_type=type1] ~ label > div::after {
  opacity: 1;
  transform: scale(1) !important;
}
body .tick_checkbox label {
  cursor: pointer;
}
body .tick_checkbox label div {
  cursor: pointer;
  height: 15px;
  width: 15px;
  border: 1px solid #ffffff;
  position: relative;
  border-radius: 2px;
}
body .tick_checkbox label div::after {
  position: absolute;
  content: "";
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  border-radius: 2px;
  background-color: #ffffff;
  opacity: 0;
  inset: 0;
  margin: auto;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}
body .tick_checkbox label[cust_type=type1] div::after {
  position: absolute;
  content: "";
  top: 0px;
  bottom: 0;
  margin: auto;
  background-color: #ffffff;
}
body .tick_checkbox label[cust_type=type1] div::after {
  opacity: 0;
  transform: scale(0) !important;
  transition: all 0.3s ease-in-out;
}
body .error {
  color: #ff2e2f;
  opacity: 0;
  transition: all 0.3s linear;
  font-size: 12px !important;
  line-height: 18px !important;
  border: 1px solid #ff2e2f;
  padding: 5px 10px;
  border-radius: 5px;
}
body .error[error=true] {
  opacity: 1;
}
body input {
  transition: border-bottom 0.3s linear;
}
body input[inp_error=true] {
  border-bottom-color: #ff2e2f !important;
}
body input[inp_error=false] {
  border-bottom-color: #67d114 !important;
}
body input[readonly] {
  cursor: not-allowed;
}
body .disable,
body .disabled {
  cursor: no-drop !important;
}
body div {
  position: relative;
}
body div .month_year_picker {
  position: absolute;
  color: #000;
  top: 30px;
  right: 0;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  background: #ffffff;
  z-index: 10;
  border-top-right-radius: 0px;
}
body div .month_year_picker .close_date_time {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 0px;
  top: 0px;
  background-color: #ffffff;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
body div .month_year_picker .close_date_time > div {
  height: 2px;
  width: 100%;
  background-color: #000;
}
body div .month_year_picker .close_date_time > div:first-child {
  transform: rotate(45deg) translateY(1px);
}
body div .month_year_picker .close_date_time > div:last-child {
  transform: rotate(-45deg) translateY(-1px);
}
body div .month_year_picker .toggel_picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
body div .month_year_picker .toggel_picker > div {
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 5px;
}
body div .month_year_picker .toggel_picker > div.active {
  border: 1px solid #000;
}
body div .month_year_picker .toggel_picker img {
  height: 12px;
}
body div .month_year_picker .toggel_picker img[current_showing=month] {
  transform: rotate(180deg);
}
body div .month_year_picker .picker_body {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  padding: 10px 0px 0px 0px;
}
body div .month_year_picker .picker_body > div {
  padding: 2px;
  width: 33%;
}
body div .month_year_picker .picker_body > div > div {
  padding: 5px;
  border-radius: 5px;
}
body div .month_year_picker .picker_body > div > div.active {
  background-color: #67d114;
}
body .test {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
body .test .hanger_animate.hanger1 {
  stroke-dasharray: 184.4;
  -webkit-animation: dash1 5s infinite linear;
          animation: dash1 5s infinite linear;
}
body .test .hanger_animate.hanger1.hanger1reverse {
  -webkit-animation: dash1rev 5s infinite linear;
          animation: dash1rev 5s infinite linear;
}
@-webkit-keyframes dash1rev {
  0%, 100% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 368.8;
  }
}
@keyframes dash1rev {
  0%, 100% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 368.8;
  }
}
@-webkit-keyframes dash1 {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 368.8;
  }
}
@keyframes dash1 {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 368.8;
  }
}
body .test .hanger_animate.hanger2 {
  stroke-dasharray: 46;
  -webkit-animation: dash2 5s infinite linear;
          animation: dash2 5s infinite linear;
}
@-webkit-keyframes dash2 {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 92;
  }
}
@keyframes dash2 {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 92;
  }
}
body .test .hanger_animate.hanger3 {
  stroke-dasharray: 30.6;
  -webkit-animation: dash3 5s infinite linear;
          animation: dash3 5s infinite linear;
}
@-webkit-keyframes dash3 {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 61.33;
  }
}
@keyframes dash3 {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 61.33;
  }
}
body .test .hanger_animate.hanger4 {
  stroke-dasharray: 23;
  -webkit-animation: dash4 5s infinite linear;
          animation: dash4 5s infinite linear;
}
@-webkit-keyframes dash4 {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 46;
  }
}
@keyframes dash4 {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 46;
  }
}
body .test .hanger_animate.hanger5 {
  stroke-dasharray: 18.4;
  -webkit-animation: dash5 5s infinite linear;
          animation: dash5 5s infinite linear;
}
@-webkit-keyframes dash5 {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 36.8;
  }
}
@keyframes dash5 {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 36.8;
  }
}
body .loading {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  inset: 0;
  background-color: #000000;
}
body .loading .hanger {
  stroke-dasharray: 46;
  -webkit-animation: dash2 5s infinite linear;
          animation: dash2 5s infinite linear;
}
@keyframes dash2 {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 92;
  }
}

@media screen and (max-width: 1040px) {
  .hidden_in_phone {
    display: none !important;
  }
}
.navbar {
  display: flex;
  justify-content: center;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  top: 0;
  right: 0;
  position: relative;
  z-index: 1000;
}
.navbar .menu {
  height: 32px;
  width: 32px;
  padding: 8.33px 3px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: 10;
}
.navbar .menu .menu_bar {
  background: #fff;
  height: 2px;
  width: 100%;
  border-radius: 2px;
  transition: all 300ms linear;
}
.navbar .menu .menu_bar.bar1 {
  transform-origin: top left;
}
.navbar .menu .menu_bar.bar2 {
  transform-origin: left;
}
.navbar .menu .menu_bar.bar3 {
  transform-origin: bottom left;
}
@media screen and (max-width: 1040px) {
  .navbar .menu {
    display: flex;
  }
}
.navbar .menu_items {
  transform: translateY(4px);
}
@media screen and (max-width: 1040px) {
  .navbar .menu_items {
    position: fixed;
    inset: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: right;
    background: rgba(0, 0, 0, 0);
    transform: translateX(100%);
    transition: all 500ms cubic-bezier(1, -0.03, 0.05, 0.99);
    transform: translateX(100%);
    text-align: right;
    z-index: 100;
  }
}
.navbar .menu_items .menu_items_main {
  display: flex;
}
.navbar .menu_items .menu_items_main > div {
  padding: 0 20px 0 20px;
}
.navbar .menu_items .menu_items_main > div[nav_tab_active=true] {
  font-weight: 700;
}
@media screen and (max-width: 1040px) {
  .navbar .menu_items .menu_items_main > div {
    padding: 0px;
  }
}
@media screen and (max-width: 1040px) {
  .navbar .menu_items .menu_items_main {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 100vh;
    flex-direction: column;
    margin-left: auto;
    background: rgb(0, 0, 0);
    padding: 152px 43px 0px 72px;
  }
  .navbar .menu_items .menu_items_main .menu_item_tab {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 17px;
  }
}
@media screen and (max-width: 1040px) {
  .navbar.active .menu_bar.bar1 {
    transform: rotate(40deg);
  }
  .navbar.active .menu_bar.bar2 {
    transform: scale(0);
  }
  .navbar.active .menu_bar.bar3 {
    transform: rotate(-40deg);
  }
  .navbar.active .menu_items {
    transform: translateX(0%);
    background: rgba(255, 255, 255, 0.2);
  }
}

.juliette {
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 42px 40px;
  top: 0;
  left: 0;
  right: 0;
  text-align: left;
  margin: 0;
  z-index: 1;
  min-height: -webkit-max-content !important;
  min-height: -moz-max-content !important;
  min-height: max-content !important;
  background: transparent;
  display: flex;
  align-items: flex-end;
  overflow-x: unset !important;
}
.juliette .menu32_dummy {
  width: 32px;
}
.juliette.bg-black {
  background: #000;
}
.juliette img {
  margin: 0 22px 0 0;
  cursor: pointer;
  width: 149px;
}
@media screen and (max-width: 1040px) {
  .juliette {
    width: 100vw;
    padding: 67px 40px 50px 42px;
    justify-content: center;
    align-items: center;
  }
  .juliette img {
    width: 111px;
    height: 21px;
    margin: 0 auto 0 auto;
  }
}

.cancelpickupmodal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
}
.cancelpickupmodal > .cancelpickupmodal_main {
  position: fixed;
  inset: 0;
  margin: auto;
  background: #000;
  width: 766px;
  height: 468px;
  border-radius: 20px;
  border: 1px solid #737373;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 1040px) {
  .cancelpickupmodal > .cancelpickupmodal_main {
    width: 301px;
    height: 269px;
  }
}
.cancelpickupmodal > .cancelpickupmodal_main > .title {
  margin: 0 0 35px 0;
}
@media screen and (max-width: 1040px) {
  .cancelpickupmodal > .cancelpickupmodal_main > .title {
    font-size: 18px;
    line-height: 23px;
  }
}
.cancelpickupmodal > .cancelpickupmodal_main .btn_group {
  display: flex;
  width: 236px;
  margin: 0 auto 0 auto;
}
@media screen and (max-width: 1040px) {
  .cancelpickupmodal > .cancelpickupmodal_main .btn_group {
    flex-direction: column-reverse;
  }
}
.cancelpickupmodal > .cancelpickupmodal_main .btn_group .btn {
  border: 1px solid #ffffff;
  color: #ffffff;
  width: 250px;
  border-radius: 6px;
  padding: 13px 0 13px 0;
}
@media screen and (max-width: 1040px) {
  .cancelpickupmodal > .cancelpickupmodal_main .btn_group .btn {
    width: 136px;
    margin: 0 auto 0 auto !important;
  }
}
.cancelpickupmodal > .cancelpickupmodal_main .btn_group .btn:first-child {
  margin-right: 3px;
}
.cancelpickupmodal > .cancelpickupmodal_main .btn_group .btn:last-child {
  margin: 0 0 0 3px;
  background: #ffffff;
  color: #000;
}
@media screen and (max-width: 1040px) {
  .cancelpickupmodal > .cancelpickupmodal_main .btn_group .btn:last-child {
    margin: 0 auto 6px auto !important;
  }
}

.howitworks {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 44px 0 44px;
}
@media screen and (max-width: 1040px) {
  .howitworks {
    background-position: 40%;
    padding: 161px 44px 0 44px;
    flex-direction: column;
    align-items: center;
  }
  .howitworks > .page_title {
    height: 0;
    overflow: hidden;
    margin: 0;
  }
}
.howitworks .howitworks_body {
  margin: 17% auto 0 19%;
}
@media screen and (max-width: 1040px) {
  .howitworks .howitworks_body {
    margin: 0% 0 0 0;
  }
}
.howitworks .howitworks_body .f40 {
  font-weight: 500;
  letter-spacing: 1.24838px;
  margin: 0 0 30px 0;
}
@media screen and (max-width: 1040px) {
  .howitworks .howitworks_body .f40 {
    font-size: 40px;
    line-height: 52px;
  }
}
.howitworks .howitworks_body .f16 {
  letter-spacing: 0.9975px;
  font-weight: 500;
}
.howitworks .howitworks_body .continue {
  height: 44px;
  width: 136px;
  color: #000;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 0 0;
  font-weight: 500;
}
.howitworks .howitworks_body .donotshowagain {
  margin: 20px 0 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.howitworks .howitworks_body .donotshowagain .tick_checkbox {
  margin-right: 12px;
  margin: 0 12px 0 0;
}

.loginpage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 112px 0 112px 0;
  position: relative;
}
@media screen and (max-width: 1040px) {
  .loginpage {
    padding: 138px 0 0 0;
  }
}
.loginpage .login_heading {
  padding: 38px 0 0 0;
}
@media screen and (max-width: 1040px) {
  .loginpage .login_heading {
    padding: 0;
  }
}
.loginpage > div:not(.dots_box) {
  position: relative;
  height: 668px;
  width: 421px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #f2f2f2;
  padding: 58px 67px 36px 66px;
  font-weight: 500;
}
@media screen and (max-width: 1040px) {
  .loginpage > div:not(.dots_box) {
    width: 100vw;
    border: 1px solid transparent;
    padding: 0px 43px 115px 44px;
  }
  .loginpage > div:not(.dots_box) .f40 {
    font-size: 40px;
  }
  .loginpage > div:not(.dots_box) .f14 {
    font-size: 14px;
  }
}
.loginpage > div:not(.dots_box).login_card .login_heading {
  max-width: 288px;
  line-height: 36px;
  margin: 0 0 55px 0;
  letter-spacing: 0.858265px;
}
.loginpage > div:not(.dots_box).login_card .login_email,
.loginpage > div:not(.dots_box).login_card .login_password {
  position: relative;
}
.loginpage > div:not(.dots_box).login_card .login_email input,
.loginpage > div:not(.dots_box).login_card .login_password input {
  background: transparent;
  width: 100%;
  height: 38px;
  border: 0;
  border-bottom: 1px solid #f2f2f2;
  color: #ffffff;
  padding: 0 0 12px 0;
  transition: border-bottom 0.3s linear;
}
.loginpage > div:not(.dots_box).login_card .login_email input[inp_error=true],
.loginpage > div:not(.dots_box).login_card .login_password input[inp_error=true] {
  border-bottom: 1px solid #ff2e2f;
}
.loginpage > div:not(.dots_box).login_card .login_email input::-moz-placeholder, .loginpage > div:not(.dots_box).login_card .login_password input::-moz-placeholder {
  color: #ffffff;
}
.loginpage > div:not(.dots_box).login_card .login_email input:-ms-input-placeholder, .loginpage > div:not(.dots_box).login_card .login_password input:-ms-input-placeholder {
  color: #ffffff;
}
.loginpage > div:not(.dots_box).login_card .login_email input::placeholder,
.loginpage > div:not(.dots_box).login_card .login_password input::placeholder {
  color: #ffffff;
}
.loginpage > div:not(.dots_box).login_card .login_email input:focus-visible,
.loginpage > div:not(.dots_box).login_card .login_password input:focus-visible {
  outline: 0;
  border-bottom: 2px solid #ffffff;
}
.loginpage > div:not(.dots_box).login_card .login_email {
  margin: 20.11px 0 26px 0;
}
.loginpage > div:not(.dots_box).login_card .login_password .password_img {
  position: absolute;
  top: 0;
  bottom: 11px;
  margin: auto;
  right: 0;
}
.loginpage > div:not(.dots_box).login_card .login_forgotpassword {
  margin: 16px 0 0 0;
}
.loginpage > div:not(.dots_box).login_card .login_forgotpassword label {
  cursor: pointer;
}
.loginpage > div:not(.dots_box).login_card .login_createaccount {
  margin: auto 0 0 0;
}
.loginpage > div:not(.dots_box).login_card .login_submit {
  margin: 26px 0 0 0;
  border-radius: 4.92783px;
  background: #ffffff;
  border: 1px solid #ffffff;
  padding: 14px 0 14px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s linear;
  color: #000;
}
.loginpage > div:not(.dots_box).login_card .login_submit.disable {
  color: #737373;
  border: 1px solid #737373;
  background: transparent;
}
.loginpage > div:not(.dots_box).login_card .login_through {
  display: flex;
}
.loginpage > div:not(.dots_box).login_card .login_through img {
  cursor: pointer;
  height: 30px;
  padding: 1px;
}
.loginpage > div:not(.dots_box).login_card .login_through img:not(:last-child) {
  margin: 0 30px 0 0;
}
.loginpage > div:not(.dots_box).login_card .login_through img:first-child {
  margin: 0 30px 0 auto;
}
.loginpage > div:not(.dots_box).login_card .login_through img:last-child {
  margin: 0 auto 0 0;
}
.loginpage > div:not(.dots_box).login_card .error {
  margin-top: 12px;
  margin: 12px 0 0 0;
  border: 1px solid #ff2e2f;
  height: 28px;
  border-radius: 5px;
  padding-inline: 10px;
  padding: 0 10px 0 10px;
  display: flex;
  align-items: center;
}
.loginpage > div:not(.dots_box).createacc {
  padding: 58px 67px 26px 66px;
}
@media screen and (max-width: 1040px) {
  .loginpage > div:not(.dots_box).createacc {
    padding: 0px 43px 105px 44px;
  }
}
.loginpage > div:not(.dots_box).createacc .createacc_heading {
  max-width: 288px;
  line-height: 36px;
  margin: 0 0 47px 0;
  letter-spacing: 0.858265px;
}
.loginpage > div:not(.dots_box).createacc .createacc_name,
.loginpage > div:not(.dots_box).createacc .createacc_number,
.loginpage > div:not(.dots_box).createacc .createacc_email,
.loginpage > div:not(.dots_box).createacc .createacc_password,
.loginpage > div:not(.dots_box).createacc .createacc_numberverification {
  position: relative;
  height: 29px;
}
.loginpage > div:not(.dots_box).createacc .createacc_name input,
.loginpage > div:not(.dots_box).createacc .createacc_number input,
.loginpage > div:not(.dots_box).createacc .createacc_email input,
.loginpage > div:not(.dots_box).createacc .createacc_password input,
.loginpage > div:not(.dots_box).createacc .createacc_numberverification input {
  border: 0;
  padding: 0;
  background: transparent;
  width: 100%;
  height: 29px;
  color: #ffffff;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 0 12px 0;
}
.loginpage > div:not(.dots_box).createacc .createacc_name input::-moz-placeholder, .loginpage > div:not(.dots_box).createacc .createacc_number input::-moz-placeholder, .loginpage > div:not(.dots_box).createacc .createacc_email input::-moz-placeholder, .loginpage > div:not(.dots_box).createacc .createacc_password input::-moz-placeholder, .loginpage > div:not(.dots_box).createacc .createacc_numberverification input::-moz-placeholder {
  color: #ffffff;
}
.loginpage > div:not(.dots_box).createacc .createacc_name input:-ms-input-placeholder, .loginpage > div:not(.dots_box).createacc .createacc_number input:-ms-input-placeholder, .loginpage > div:not(.dots_box).createacc .createacc_email input:-ms-input-placeholder, .loginpage > div:not(.dots_box).createacc .createacc_password input:-ms-input-placeholder, .loginpage > div:not(.dots_box).createacc .createacc_numberverification input:-ms-input-placeholder {
  color: #ffffff;
}
.loginpage > div:not(.dots_box).createacc .createacc_name input::placeholder,
.loginpage > div:not(.dots_box).createacc .createacc_number input::placeholder,
.loginpage > div:not(.dots_box).createacc .createacc_email input::placeholder,
.loginpage > div:not(.dots_box).createacc .createacc_password input::placeholder,
.loginpage > div:not(.dots_box).createacc .createacc_numberverification input::placeholder {
  color: #ffffff;
}
.loginpage > div:not(.dots_box).createacc .createacc_name input:focus-visible,
.loginpage > div:not(.dots_box).createacc .createacc_number input:focus-visible,
.loginpage > div:not(.dots_box).createacc .createacc_email input:focus-visible,
.loginpage > div:not(.dots_box).createacc .createacc_password input:focus-visible,
.loginpage > div:not(.dots_box).createacc .createacc_numberverification input:focus-visible {
  outline: 0;
  border-bottom: 2px solid #ffffff;
}
.loginpage > div:not(.dots_box).createacc .createacc_name {
  margin: 20.11px 0 0 0;
}
.loginpage > div:not(.dots_box).createacc .createacc_number,
.loginpage > div:not(.dots_box).createacc .createacc_email,
.loginpage > div:not(.dots_box).createacc .createacc_password {
  margin: 25px 0 0 0;
}
.loginpage > div:not(.dots_box).createacc .createacc_numberverification {
  height: 0;
  overflow: hidden;
  padding: 0 0 0 0;
  transition: all 0.3s linear;
  position: relative;
}
.loginpage > div:not(.dots_box).createacc .createacc_numberverification.createacc_numberverification_active {
  cursor: pointer;
  padding: 25px 0 0 0;
  height: 57px;
}
.loginpage > div:not(.dots_box).createacc .createacc_numberverification .createacc_numberverification_btn {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 5px;
  border: 1px solid #fff;
  height: 28px;
  padding: 0 22px 0 22px;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}
.loginpage > div:not(.dots_box).createacc .createacc_numberverification .createacc_numberverification_btn.createacc_numberverification_btn_to_right {
  top: 10px;
  left: unset;
}
.loginpage > div:not(.dots_box).createacc .createacc_password .password_img {
  position: absolute;
  top: 0;
  bottom: 11px;
  margin: auto;
  right: 0;
}
.loginpage > div:not(.dots_box).createacc .createacc_login {
  margin: auto 0 0 0;
}
.loginpage > div:not(.dots_box).createacc .createacc_agreement_btn {
  display: flex;
  align-items: end;
  margin: 18px 0 0 0;
}
.loginpage > div:not(.dots_box).createacc .createacc_agreement_btn .agreement {
  font-size: 10px;
  line-height: 14px;
}
.loginpage > div:not(.dots_box).createacc .createacc_agreement_btn .submit {
  width: 136px;
  height: 44px;
  margin: 0 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  background: #ffffff;
  color: #000;
  border-radius: 4.92783px;
}
.loginpage > div:not(.dots_box).createacc .createacc_agreement_btn .submit.disabled {
  border: 0.821306px solid #737373;
  background: transparent;
  color: #737373;
}
.loginpage > div:not(.dots_box).createacc .error {
  padding: 5px 10px;
  border: 1px solid #ff2e2f;
  margin: 6px 0 9px 0;
  border-radius: 5px;
}
.loginpage > div:not(.dots_box).verifyyourself {
  padding-bottom: 55px;
}
@media screen and (max-width: 1040px) {
  .loginpage > div:not(.dots_box).verifyyourself {
    padding-bottom: 105px;
  }
}
.loginpage > div:not(.dots_box).verifyyourself > .verifyyourself_body {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.loginpage > div:not(.dots_box).verifyyourself > .verifyyourself_body .resend_code {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  margin: 9px 0 0 0;
  border-radius: 5px;
  cursor: pointer;
}
.loginpage > div:not(.dots_box).verifyyourself > .verifyyourself_body .error {
  margin: 9px 0 -3px 0;
}
.loginpage > div:not(.dots_box).verifyyourself .verifyyourself_heading {
  max-width: 288px;
  line-height: 36px;
  margin: 0 0 47px 0;
  letter-spacing: 0.858265px;
}
.loginpage > div:not(.dots_box).verifyyourself .verifyyourself_number {
  position: relative;
  margin: 25px 0 0 0;
  height: 29px;
}
.loginpage > div:not(.dots_box).verifyyourself .verifyyourself_number input {
  border: 0;
  padding: 0;
  background: transparent;
  width: 100%;
  height: 29px;
  color: #ffffff;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 12px;
}
.loginpage > div:not(.dots_box).verifyyourself .verifyyourself_number input::-moz-placeholder {
  color: #ffffff;
}
.loginpage > div:not(.dots_box).verifyyourself .verifyyourself_number input:-ms-input-placeholder {
  color: #ffffff;
}
.loginpage > div:not(.dots_box).verifyyourself .verifyyourself_number input::placeholder {
  color: #ffffff;
}
.loginpage > div:not(.dots_box).verifyyourself .verifyyourself_number input:focus-visible {
  outline: 0;
  border-bottom: 2px solid #ffffff;
}
.loginpage > div:not(.dots_box).verifyyourself .verifyyourself_login {
  margin: auto 0 0 0;
}
.loginpage > div:not(.dots_box).verifyyourself .verifyyourself_agreement_btn {
  display: flex;
  align-items: end;
  margin: 18px 0 0 0;
  -moz-column-gap: 16px;
       column-gap: 16px;
}
.loginpage > div:not(.dots_box).verifyyourself .verifyyourself_agreement_btn .agreement {
  font-size: 10px;
  line-height: 14px;
}
.loginpage > div:not(.dots_box).verifyyourself .verifyyourself_agreement_btn .submit,
.loginpage > div:not(.dots_box).verifyyourself .verifyyourself_agreement_btn .previous {
  width: 136px;
  height: 44px;
  margin: 0 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  background: #ffffff;
  color: #000;
  border-radius: 4.92783px;
}
.loginpage > div:not(.dots_box).verifyyourself .verifyyourself_agreement_btn .submit.disabled,
.loginpage > div:not(.dots_box).verifyyourself .verifyyourself_agreement_btn .previous.disabled {
  border: 0.821306px solid #737373;
  background: transparent;
  color: #737373;
}
.loginpage > div:not(.dots_box).verifyyourself .verifyyourself_agreement_btn .previous {
  background: #000;
  color: #ffffff;
  border: 1px solid #ffffff;
  margin: 0;
}
.loginpage > div:not(.dots_box).verifyyourself .error {
  padding: 5px 10px;
  border: 1px solid #ff2e2f;
  margin: 0 9px 0 6px;
  border-radius: 5px;
}
.loginpage > div:not(.dots_box).almostthere {
  padding: 58px 67px 55px 66px;
}
@media screen and (max-width: 1040px) {
  .loginpage > div:not(.dots_box).almostthere {
    padding: 58px 67px 105px 66px;
  }
}
.loginpage > div:not(.dots_box).almostthere .almostthere_heading {
  max-width: 288px;
  line-height: 36px;
  margin: 0 0 47px 0;
  letter-spacing: 0.858265px;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_address,
.loginpage > div:not(.dots_box).almostthere .almostthere_apartmentno,
.loginpage > div:not(.dots_box).almostthere .almostthere_zipcode {
  position: relative;
  height: 29px;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_address input,
.loginpage > div:not(.dots_box).almostthere .almostthere_apartmentno input,
.loginpage > div:not(.dots_box).almostthere .almostthere_zipcode input {
  border: 0;
  padding: 0;
  height: 29px;
  background: transparent;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid #f2f2f2;
  color: #ffffff;
  padding: 0 0 12px 0;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_address input::-moz-placeholder, .loginpage > div:not(.dots_box).almostthere .almostthere_apartmentno input::-moz-placeholder, .loginpage > div:not(.dots_box).almostthere .almostthere_zipcode input::-moz-placeholder {
  color: #ffffff;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_address input:-ms-input-placeholder, .loginpage > div:not(.dots_box).almostthere .almostthere_apartmentno input:-ms-input-placeholder, .loginpage > div:not(.dots_box).almostthere .almostthere_zipcode input:-ms-input-placeholder {
  color: #ffffff;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_address input::placeholder,
.loginpage > div:not(.dots_box).almostthere .almostthere_apartmentno input::placeholder,
.loginpage > div:not(.dots_box).almostthere .almostthere_zipcode input::placeholder {
  color: #ffffff;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_address input:focus-visible,
.loginpage > div:not(.dots_box).almostthere .almostthere_apartmentno input:focus-visible,
.loginpage > div:not(.dots_box).almostthere .almostthere_zipcode input:focus-visible {
  outline: 0;
  border-bottom: 2px solid #ffffff;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_address {
  margin: 20.11px 0 0 0;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_apartmentno,
.loginpage > div:not(.dots_box).almostthere .almostthere_zipcode {
  margin: 25px 0 0 0;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_i_have_a_doorman {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 25px 0 auto 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_i_have_a_doorman .tick_checkbox {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 13px 0 0;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_please_note {
  margin: 0 0 13px 0;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_prevnext {
  margin: 25.51px 0 0 0;
  display: flex;
  height: 44px;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_prevnext > div {
  width: 50%;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_prevnext > div > div {
  font-size: 14px;
  line-height: 18px;
  height: 44px;
  padding: 14px 0 13px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4.92783px;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_prevnext > div.almostthere_prev > div {
  border: 0.821306px solid #ffffff;
  margin: 0 8px 0 0;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_prevnext > div.almostthere_next > div {
  border: 0.821306px solid #ffffff;
  margin: 0 0 0 8px;
  background: #ffffff;
  color: #000;
  transition: 300ms linear all;
}
.loginpage > div:not(.dots_box).almostthere .almostthere_prevnext > div.almostthere_next > div.disabled {
  border: 0.821306px solid #737373;
  background: transparent;
  color: #737373;
}
.loginpage > div:not(.dots_box).daretobedirty {
  padding: 58px 67px 55px 66px;
}
@media screen and (max-width: 1040px) {
  .loginpage > div:not(.dots_box).daretobedirty {
    padding: 58px 67px 105px 66px;
  }
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_heading {
  max-width: 288px;
  line-height: 36px;
  margin-bottom: 55px;
  letter-spacing: 0.858265px;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_nameoncard,
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_cardnumber,
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_expirydate,
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_cvv {
  position: relative;
  height: 29px;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_nameoncard input,
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_cardnumber input,
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_expirydate input,
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_cvv input {
  height: 29px;
  background: transparent;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #f2f2f2;
  color: #ffffff;
  padding: 0;
  padding: 0 0 12px 0;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_nameoncard input::-moz-placeholder, .loginpage > div:not(.dots_box).daretobedirty .daretobedirty_cardnumber input::-moz-placeholder, .loginpage > div:not(.dots_box).daretobedirty .daretobedirty_expirydate input::-moz-placeholder, .loginpage > div:not(.dots_box).daretobedirty .daretobedirty_cvv input::-moz-placeholder {
  color: #ffffff;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_nameoncard input:-ms-input-placeholder, .loginpage > div:not(.dots_box).daretobedirty .daretobedirty_cardnumber input:-ms-input-placeholder, .loginpage > div:not(.dots_box).daretobedirty .daretobedirty_expirydate input:-ms-input-placeholder, .loginpage > div:not(.dots_box).daretobedirty .daretobedirty_cvv input:-ms-input-placeholder {
  color: #ffffff;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_nameoncard input::placeholder,
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_cardnumber input::placeholder,
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_expirydate input::placeholder,
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_cvv input::placeholder {
  color: #ffffff;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_nameoncard input:focus-visible,
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_cardnumber input:focus-visible,
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_expirydate input:focus-visible,
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_cvv input:focus-visible {
  border-bottom: 2px solid #ffffff;
  outline: 0;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_nameoncard {
  margin-top: 20.11px;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_cardnumber,
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_expirydate,
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_cvv {
  margin-top: 25px;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_cvv {
  position: relative;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_cvv .cvv_img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 20.83px;
  width: 20.83px;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_haveanaccount {
  margin-top: auto;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_prevnext {
  margin-top: 25.51px;
  display: flex;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_prevnext > div {
  width: 50%;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_prevnext > div > div {
  height: 44px;
  padding: 14px 0 13px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4.92783px;
  font-size: 14px;
  line-height: 18px;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_prevnext > div.daretobedirty_prev > div {
  border: 0.821306px solid #ffffff;
  margin-right: 8px;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_prevnext > div.daretobedirty_next > div {
  border: 0.821306px solid #ffffff;
  margin-left: 8px;
  background: #ffffff;
  color: #000;
  transition: 300ms linear all;
}
.loginpage > div:not(.dots_box).daretobedirty .daretobedirty_prevnext > div.daretobedirty_next > div.disabled {
  border: 0.821306px solid #737373;
  background: transparent;
  color: #737373;
}
.loginpage > div:not(.dots_box).daretobedirty .error {
  margin-top: 9px;
  border: 1px solid red;
  height: 28px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 10px;
}
.loginpage > div:not(.dots_box) .options_or {
  margin: 14px 0;
  text-align: center;
  transition: all linear 0.3s;
  overflow: hidden;
  opacity: 1;
}
.loginpage > div:not(.dots_box) .options_or.options_mini {
  height: 0;
  opacity: 0;
}
.loginpage > div:not(.dots_box) .login_through {
  display: flex;
}
.loginpage > div:not(.dots_box) .login_through.options_mini {
  height: 0;
  overflow: hidden;
}
.loginpage > div:not(.dots_box) .login_through img {
  cursor: pointer;
  height: 30px;
  padding: 1px;
  margin: 0 30px 0 0;
}
.loginpage > div:not(.dots_box) .login_through img:not(:last-child) {
  margin: 0 30px 0 0;
}
.loginpage > div:not(.dots_box) .login_through img:first-child {
  margin: 0 30px 0 auto;
}
.loginpage > div:not(.dots_box) .login_through img:last-child {
  margin: 0 auto 0 0;
}
.loginpage .dots_box {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  transition: all 300ms linear;
  display: flex;
  flex-direction: column;
}
.loginpage .dots_box.not_show {
  left: -10px;
  opacity: 0;
}
.loginpage .dots_box.not_show .dot:nth-child(1) {
  background: #ffffff;
}
.loginpage .dots_box.not_show .dot:nth-child(1).login {
  order: 1;
}
.loginpage .dots_box.not_show .dot:nth-child(2).login {
  order: 2;
}
.loginpage .dots_box.not_show .dot:nth-child(3).login {
  order: 3;
}
.loginpage .dots_box.show {
  opacity: 1;
  left: -44.43px;
}
.loginpage .dots_box > .dot {
  height: 6.57px;
  width: 6.57px;
  background-color: #737373;
  border-radius: 50%;
  transition: all 300ms linear;
  margin: 7px 0 7px 0;
}
.loginpage .dots_box > .dot:first-child {
  margin: 0 0 7px 0;
}
.loginpage .dots_box > .dot:last-child {
  margin: 7px 0 0 0;
}
.loginpage .dots_box > .dot.active {
  background-color: #ffffff;
}
@media screen and (max-width: 1040px) {
  .loginpage .dots_box {
    position: unset;
    flex-direction: row;
    margin: 50px auto 50px auto;
  }
  .loginpage .dots_box .dot {
    margin: 0 7px 0 7px;
  }
  .loginpage .dots_box .dot:first-child {
    margin: 0 7px 0 0;
  }
  .loginpage .dots_box .dot:last-child {
    margin: 0 0 0 7px;
  }
  .loginpage .dots_box .dot.active {
    background-color: #ffffff;
  }
}

.forgotpassword {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-block: 131px;
  padding: 131px 0 131px 0;
  font-weight: 500;
}
@media screen and (max-width: 1040px) {
  .forgotpassword {
    padding-top: 148px;
    padding-bottom: 0px;
    padding: 148px 0 0 0;
  }
}
.forgotpassword .forgotpassword_card {
  height: 668px;
  width: 421px;
  display: flex;
  flex-direction: column;
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  padding: 58px 67px 55px 66px;
}
@media screen and (max-width: 1040px) {
  .forgotpassword .forgotpassword_card {
    width: 100vw;
    border: 1px solid transparent;
    padding: 0px 43px 115px 44px;
  }
  .forgotpassword .forgotpassword_card .f40 {
    font-size: 40px;
  }
  .forgotpassword .forgotpassword_card .f14 {
    font-size: 14px;
  }
}
.forgotpassword .forgotpassword_card .forgotpassword_heading {
  max-width: 288px;
  line-height: 36px;
  margin: 0 0 55px 0;
  letter-spacing: 0.858265px;
}
.forgotpassword .forgotpassword_card .forgotpassword_email,
.forgotpassword .forgotpassword_card .forgotpassword_password {
  position: relative;
}
.forgotpassword .forgotpassword_card .forgotpassword_email input,
.forgotpassword .forgotpassword_card .forgotpassword_password input {
  background: transparent;
  width: 100%;
  height: 38px;
  border: 0;
  border-bottom: 1px solid #f2f2f2;
  color: #ffffff;
  padding: 0;
  padding: 0 0 12px 0;
}
.forgotpassword .forgotpassword_card .forgotpassword_email input::-moz-placeholder, .forgotpassword .forgotpassword_card .forgotpassword_password input::-moz-placeholder {
  color: #ffffff;
}
.forgotpassword .forgotpassword_card .forgotpassword_email input:-ms-input-placeholder, .forgotpassword .forgotpassword_card .forgotpassword_password input:-ms-input-placeholder {
  color: #ffffff;
}
.forgotpassword .forgotpassword_card .forgotpassword_email input::placeholder,
.forgotpassword .forgotpassword_card .forgotpassword_password input::placeholder {
  color: #ffffff;
}
.forgotpassword .forgotpassword_card .forgotpassword_email input:focus-visible,
.forgotpassword .forgotpassword_card .forgotpassword_password input:focus-visible {
  outline: 0;
}
.forgotpassword .forgotpassword_card .forgotpassword_email {
  margin: 20.11px 0 auto 0;
}
.forgotpassword .forgotpassword_card .forgotpassword_password {
  position: relative;
  padding: 15px 0 0 0;
}
.forgotpassword .forgotpassword_card .forgotpassword_password input {
  background: transparent;
  width: 100%;
  height: 38px;
  border: 0;
  border-bottom: 1px solid #f2f2f2;
  color: #ffffff;
  padding: 0 0 12px 0;
}
.forgotpassword .forgotpassword_card .forgotpassword_password input::-moz-placeholder {
  color: #ffffff;
}
.forgotpassword .forgotpassword_card .forgotpassword_password input:-ms-input-placeholder {
  color: #ffffff;
}
.forgotpassword .forgotpassword_card .forgotpassword_password input::placeholder {
  color: #ffffff;
}
.forgotpassword .forgotpassword_card .forgotpassword_password input:focus-visible {
  outline: 0;
}
.forgotpassword .forgotpassword_card .forgotpassword_password .password_img {
  position: absolute;
  top: 10px;
  bottom: 10px;
  margin: auto;
  right: 0;
}
.forgotpassword .forgotpassword_card .forgotpassword_resendcode,
.forgotpassword .forgotpassword_card .forgotpassword_cancel {
  border-radius: 4.92783px;
  background: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  padding-block: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.forgotpassword .forgotpassword_card .forgotpassword_resendcode {
  margin: auto 0 6px 0;
}
.forgotpassword .forgotpassword_card .forgotpassword_submit {
  margin: 6px 0 0 0;
  border-radius: 4.92783px;
  background: #ffffff;
  border: 1px solid #ffffff;
  color: #000;
  padding: 14px 0 14px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s linear;
}
.forgotpassword .forgotpassword_card .forgotpassword_submit.disable {
  color: #737373;
  border: 1px solid #737373;
  background: transparent;
}

.requestpickup {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 1040px) {
  .requestpickup {
    padding: 149px 44px 0px 43px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .requestpickup > .page_title {
    flex-grow: 1;
  }
  .requestpickup > .page_title ~ div {
    flex-grow: 1;
  }
}
.requestpickup.has_bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://julietteimages.s3.amazonaws.com/systemimages/request-pickup-min.jpg");
}
@media screen and (max-width: 1040px) {
  .requestpickup.has_bg {
    background-position: 40%;
  }
}
.requestpickup .requestpickup_body {
  padding: 0px 44px 112px 44px;
  margin: 112px 0 0 0;
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  max-height: calc(100vh - 112px);
  align-items: center;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body {
    padding: 0px;
    margin: 0;
    max-height: unset;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}
.requestpickup .requestpickup_body > div:not(.cancelpickupmodal) {
  max-width: 389px;
  margin: auto;
  text-align: center;
}
.requestpickup .requestpickup_body .requestpickup_hanger {
  margin: 0 0 16.14px 0;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body .requestpickup_hanger {
    width: 61px;
  }
}
.requestpickup .requestpickup_body .requestpickup_nav_tabs {
  position: relative;
}
.requestpickup .requestpickup_body .requestpickup_nav_tabs .requestpickup_tabs > label,
.requestpickup .requestpickup_body .requestpickup_nav_tabs .requestpickup_tabs > textarea {
  width: 100%;
  display: block;
  padding: 18px 23px 17px 23px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  text-align: center;
  color: #b9b9b9;
}
.requestpickup .requestpickup_body .requestpickup_nav_tabs .requestpickup_tabs > textarea {
  border: 0;
  border: 1px solid #ffffff;
  background: transparent;
  color: #ffffff;
}
.requestpickup .requestpickup_body .requestpickup_nav_tabs .requestpickup_tabs > input[type=checkbox],
.requestpickup .requestpickup_body .requestpickup_nav_tabs .requestpickup_tabs > input[type=file] {
  display: none;
}
.requestpickup .requestpickup_body .requestpickup_nav_tabs .requestpickup_tabs > input[type=checkbox]:checked ~ label,
.requestpickup .requestpickup_body .requestpickup_nav_tabs .requestpickup_tabs > input[type=file]:checked ~ label {
  box-shadow: 0 0 0 2px #ffffff;
  color: #ffffff;
}
.requestpickup .requestpickup_body .requestpickup_nav_tabs .requestpickup_tabs:not(.other_main) {
  margin: 0px 0px 8px 0px;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body .requestpickup_nav_tabs .requestpickup_tabs.other_main {
    margin: 0px 0px 8px 0px;
  }
}
.requestpickup .requestpickup_body .requestpickup_confirm {
  margin: 16px 0px 0px 0px;
  padding: 10px 0px 9px 0px;
  box-shadow: 0 0 0 0.777px #737373, 0 0 0 2px transparent;
  border-radius: 5px;
  text-align: center;
  width: 198px;
  color: #737373;
  transition: all 300ms linear;
  font-weight: 500;
}
.requestpickup .requestpickup_body .requestpickup_confirm[active=true] {
  color: #000;
  background: #ffffff;
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 2px transparent;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body .requestpickup_confirm {
    width: 136px;
  }
}
.requestpickup .requestpickup_body.addphotoandnote {
  margin: -1px;
  overflow-y: auto;
  overflow-x: visible;
  padding: 121px 1px 121px 1px;
  max-height: calc(100vh - 149px) !important;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body.addphotoandnote {
    padding: 1px 1px 121px 1px;
    flex-grow: unset;
  }
}
.requestpickup .requestpickup_body.addphotoandnote label,
.requestpickup .requestpickup_body.addphotoandnote textarea, .requestpickup .requestpickup_body.finalpage label,
.requestpickup .requestpickup_body.finalpage textarea {
  text-align: left !important;
  padding: 0px 24px 0px 24px;
}
.requestpickup .requestpickup_body.addphotoandnote textarea, .requestpickup .requestpickup_body.finalpage textarea {
  resize: none;
}
.requestpickup .requestpickup_body.addphotoandnote textarea::-moz-placeholder, .requestpickup .requestpickup_body.finalpage textarea::-moz-placeholder {
  color: #ffffff;
  font-size: 17px;
}
.requestpickup .requestpickup_body.addphotoandnote textarea:-ms-input-placeholder, .requestpickup .requestpickup_body.finalpage textarea:-ms-input-placeholder {
  color: #ffffff;
  font-size: 17px;
}
.requestpickup .requestpickup_body.addphotoandnote textarea::placeholder, .requestpickup .requestpickup_body.finalpage textarea::placeholder {
  color: #ffffff;
  font-size: 17px;
}
.requestpickup .requestpickup_body.addphotoandnote textarea:focus-visible, .requestpickup .requestpickup_body.finalpage textarea:focus-visible {
  outline: 0;
}
.requestpickup .requestpickup_body.addphotoandnote .txt, .requestpickup .requestpickup_body.finalpage .txt {
  text-align: center;
  max-width: 657px;
  letter-spacing: 0.9975px;
  margin: 0 auto 68px auto;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body.addphotoandnote .txt, .requestpickup .requestpickup_body.finalpage .txt {
    margin: 0 auto 50px auto;
  }
}
.requestpickup .requestpickup_body.addphotoandnote .txt .txt2, .requestpickup .requestpickup_body.finalpage .txt .txt2 {
  display: none;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body.addphotoandnote .txt .txt1, .requestpickup .requestpickup_body.finalpage .txt .txt1 {
    display: none;
  }
  .requestpickup .requestpickup_body.addphotoandnote .txt .txt2, .requestpickup .requestpickup_body.finalpage .txt .txt2 {
    display: block;
  }
}
.requestpickup .requestpickup_body.addphotoandnote .images_colection, .requestpickup .requestpickup_body.finalpage .images_colection {
  display: flex;
  flex-wrap: wrap;
  padding: 13px;
  padding: 20px 0px 0px 0px;
  border: 1px solid #fff;
  margin: -5px 0 0 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top: 0;
}
.requestpickup .requestpickup_body.addphotoandnote .images_colection .img_div, .requestpickup .requestpickup_body.finalpage .images_colection .img_div {
  height: 58px;
  width: 25%;
  display: inline-block;
  padding: 3px;
}
.requestpickup .requestpickup_body.addphotoandnote .images_colection .img_div > div, .requestpickup .requestpickup_body.finalpage .images_colection .img_div > div {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.requestpickup .requestpickup_body.addphotoandnote .images_colection .img_div img, .requestpickup .requestpickup_body.finalpage .images_colection .img_div img {
  height: 100%;
  width: auto;
  border-radius: 5px;
}
.requestpickup .requestpickup_body.addphotoandnote .requestpickup_cancelpickup, .requestpickup .requestpickup_body.finalpage .requestpickup_cancelpickup {
  margin: 8px 0 0 0;
  padding: 10px 0px 90px 0px;
  box-shadow: 0 0 0 0.777px #ffffff, 0 0 0 2px transparent;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  transition: all 300ms linear;
}
.requestpickup .requestpickup_body.addphotoandnote .btns_group, .requestpickup .requestpickup_body.finalpage .btns_group {
  display: flex;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body.addphotoandnote .btns_group, .requestpickup .requestpickup_body.finalpage .btns_group {
    flex-direction: column-reverse;
    margin: 20px auto 0 auto;
  }
}
.requestpickup .requestpickup_body.addphotoandnote .btns_group > div, .requestpickup .requestpickup_body.finalpage .btns_group > div {
  margin: 0;
  width: 50%;
  padding: 13px 0px 13px 0px;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body.addphotoandnote .btns_group > div, .requestpickup .requestpickup_body.finalpage .btns_group > div {
    width: 100%;
  }
}
.requestpickup .requestpickup_body.addphotoandnote .btns_group > div:first-child, .requestpickup .requestpickup_body.finalpage .btns_group > div:first-child {
  margin: 0 10px 0 0;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body.addphotoandnote .btns_group > div:first-child, .requestpickup .requestpickup_body.finalpage .btns_group > div:first-child {
    margin: 5px 0 0 0;
  }
}
.requestpickup .requestpickup_body.addphotoandnote .btns_group > div:last-child, .requestpickup .requestpickup_body.finalpage .btns_group > div:last-child {
  margin: 0 0 0 10px;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body.addphotoandnote .btns_group > div:last-child, .requestpickup .requestpickup_body.finalpage .btns_group > div:last-child {
    margin: 0;
  }
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body.finalpage {
    flex-grow: unset;
    padding: 0 0 149px 0;
  }
}
.requestpickup .requestpickup_body.finalpage .txt {
  margin: 0 0 30px 0;
}
.requestpickup .requestpickup_body.finalpage .requestpickup_nav_tabs {
  margin: 0 auto 47px auto;
}
.requestpickup .requestpickup_body.youwillrecieveatext .txt {
  margin: 0 0 47px 0;
  letter-spacing: 0.9975px;
}
.requestpickup .requestpickup_body.youwillrecieveatext .txt .txt2 {
  display: none;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body.youwillrecieveatext .txt .txt1 {
    display: none;
  }
  .requestpickup .requestpickup_body.youwillrecieveatext .txt .txt2 {
    display: block;
  }
}
.requestpickup .requestpickup_body.youwillrecieveatext .requestpickup_confirm {
  width: 266px;
  margin: auto;
}
.requestpickup .requestpickup_body .others_extra {
  position: absolute;
  font-weight: 500;
  background-color: #000 !important;
  z-index: 1;
  top: 190px;
  padding: 18px 47px 16px 47px;
  border-radius: 5px;
  transition: all 0.4s linear;
  display: none;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body .others_extra {
    position: unset;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    padding: 7px 16px;
    border: 1px solid #ffffff;
  }
  .requestpickup .requestpickup_body .others_extra[show=true] {
    display: block !important;
  }
}
.requestpickup .requestpickup_body .other_main:hover ~ .others_extra {
  display: block;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body .other_main:hover ~ .others_extra {
    display: none;
  }
}
.requestpickup .requestpickup_body .requestpickup_default_address {
  cursor: pointer;
  height: 47px;
  max-width: 339px;
  margin: 10px auto 8px auto !important;
}
.requestpickup .requestpickup_body .requestpickup_default_address .cust_dropdown {
  text-align: left;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 44px 0px 44px;
  position: relative;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  transition: all 0.3s linear;
  box-shadow: inset 0 0 0 1px #000;
}
.requestpickup .requestpickup_body .requestpickup_default_address .cust_dropdown > .default {
  margin: 0 12px 0 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  width: 235px;
}
.requestpickup .requestpickup_body .requestpickup_default_address .cust_dropdown > img {
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}
.requestpickup .requestpickup_body .requestpickup_default_address .cust_dropdown .cust_dropdown_body {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  z-index: 10;
  background: #000;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  transition: all 0.3s linear;
  box-shadow: inset 0 0 0 1px #ffffff;
}
.requestpickup .requestpickup_body .requestpickup_default_address .cust_dropdown .cust_dropdown_body > div {
  padding: 15px 0px 15px 0px;
  text-align: left;
}
.requestpickup .requestpickup_body .requestpickup_default_address .cust_dropdown .cust_dropdown_body > div > div {
  padding: 10px 44px 10px 44px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.requestpickup .requestpickup_body .requestpickup_default_address .cust_dropdown .cust_dropdown_body > div > div.current_selected {
  font-weight: 700;
}
.requestpickup .requestpickup_body .requestpickup_default_address .cust_dropdown.cust_dropdown_active {
  box-shadow: inset 0 0 0 1px #ffffff;
}
.requestpickup .requestpickup_body .requestpickup_default_address .cust_dropdown.cust_dropdown_active > img {
  transform: rotate(180deg);
}
.requestpickup .requestpickup_body .requestpickup_default_address .cust_dropdown.cust_dropdown_active .cust_dropdown_body {
  border-top: 0;
}
.requestpickup .requestpickup_body .requestpickup_reminder {
  margin: 60px auto 0 auto !important;
  font-weight: 500;
  color: #f2f2f2;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body .requestpickup_reminder {
    width: 250px;
    font-size: 14px;
    line-height: 18px;
  }
}
.requestpickup .requestpickup_body .gotit_btn_group {
  flex-direction: column-reverse;
  align-items: center;
}
.requestpickup .requestpickup_body .gotit_btn_group > div {
  width: 288px !important;
  margin: 0 !important;
}
.requestpickup .requestpickup_body .gotit_btn_group > div:last-child {
  margin: 0 0 6px 0 !important;
}
.requestpickup .requestpickup_body .gotit_btn_group > div:first-child {
  color: #d8d8d8 !important;
  box-shadow: 0 0 0 0.777px #d8d8d8, 0 0 0 2px transparent;
}
.requestpickup .requestpickup_body .addphotoandnotemodal {
  z-index: 100;
  position: fixed;
  inset: 0;
  max-width: 100vw !important;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div {
  background: #000;
  border: 7px solid #ffffff;
  width: 541.09px;
  height: 372px;
  border-radius: 41px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body .addphotoandnotemodal > div {
    border: 3.6px solid #ffffff;
    width: 288px;
    height: 239px;
    border-radius: 21px;
  }
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .action_btns {
  background: #ffffff;
  height: 55px;
  width: 100%;
  top: 0;
  left: 0;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 24px 7px 19px;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body .addphotoandnotemodal > div .action_btns {
    padding: 0px 13px 5px 10px;
    height: 26px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .requestpickup .requestpickup_body .addphotoandnotemodal > div .action_btns * {
    height: 18px !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .requestpickup .requestpickup_body .addphotoandnotemodal > div .action_btns .close_btn img {
    height: 11px !important;
    width: 11px !important;
  }
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .action_btns > div {
  cursor: pointer;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .action_btns .add_btn input {
  display: none;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .action_btns .add_btn label {
  padding: 0;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img {
  height: calc(100% - 55px);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img {
    height: calc(100% - 26px);
  }
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .slick-slider {
  height: 100%;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .slick-slider .slick-list {
  height: 100%;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .slick-slider .slick-list .slick-track {
  top: 0;
  bottom: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .slick-slider .slick-list .slick-track .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .slick-slider .slick-list .slick-track .slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .slick-slider img {
  margin: auto;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .slick-slider .slick-arrow.slick-next {
  right: 18px;
  z-index: 10;
  height: 27px;
  width: 15px;
  background: url("../img/addimagesmodal/arrow_right_icon.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .slick-slider .slick-arrow.slick-next {
    height: 15px;
    width: 9px;
  }
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .slick-slider .slick-arrow.slick-next:before {
  content: "";
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .slick-slider .slick-arrow.slick-prev {
  height: 27px;
  width: 15px;
  left: 18px;
  z-index: 10;
  background: url("../img/addimagesmodal/arrow_left_icon.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 1040px) {
  .requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .slick-slider .slick-arrow.slick-prev {
    height: 15px;
    width: 9px;
  }
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .slick-slider .slick-arrow.slick-prev:before {
  content: "";
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .dots_to_show {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .dots_to_show > .dot {
  height: 5px;
  width: 5px;
  background-color: #c4c4c4;
  opacity: 0.7;
  border-radius: 50%;
  transition: all 0.3s linear;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .dots_to_show > .dot.dot_active {
  background-color: #ffffff;
  opacity: 1;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .dots_to_show > .dot:not(:last-child) {
  margin: 0 3px 0 0;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .delete_confirm_modal {
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .delete_confirm_modal .txt {
  margin: 0 0 16px 0;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .delete_confirm_modal .btn {
  width: 200px;
  height: 36.23px;
  background: #ffffff;
  color: #000;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.requestpickup .requestpickup_body .addphotoandnotemodal > div .show_img .delete_confirm_modal .btn:last-child {
  margin: 7.77px 0 0 0;
}

.myorders {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 131px;
  padding-inline: 44px;
}
@media screen and (max-width: 1040px) {
  .myorders {
    text-align: left;
    padding-top: 161px;
    padding-bottom: 36px;
    flex-direction: column;
    align-items: center;
  }
  .myorders > .page_title {
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
  }
  .myorders > .page_title ~ div {
    flex-grow: 1;
    width: 100%;
  }
}
.myorders .myorder_body {
  flex-grow: 1;
}
@media screen and (max-width: 1040px) {
  .myorders .myorder_body {
    flex-grow: unset !important;
  }
}
.myorders .myorder_body .heading {
  font-weight: 700;
}
.myorders .myorder_body .request_pickup_btn {
  position: fixed;
  top: 48px;
  right: 20%;
  z-index: 100;
  height: 35px;
  width: 195px;
}
.myorders .myorder_body .request_pickup_btn > div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 6px;
  color: #000;
}
@media screen and (max-width: 1040px) {
  .myorders .myorder_body .request_pickup_btn {
    top: unset;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 97px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
  }
  .myorders .myorder_body .request_pickup_btn > div {
    height: 37px;
    width: 288px;
    border: 1px solid #ffffff;
    background: transparent;
    color: #ffffff;
  }
}
.myorders .myorder_body .f30 {
  font-weight: 500;
  margin-bottom: 13px;
}
.myorders .myorder_body .order_card {
  max-width: 879px;
  margin: auto;
}
.myorders .myorder_body .order_card:not(:last-child) {
  margin-bottom: 62px;
}
@media screen and (max-width: 1040px) {
  .myorders .myorder_body .order_card {
    margin-bottom: 60px;
  }
}
.myorders .myorder_body .order_card .order_data {
  display: flex;
}
@media screen and (max-width: 1040px) {
  .myorders .myorder_body .order_card .order_data {
    flex-wrap: wrap;
  }
}
.myorders .myorder_body .order_card .order_data > div {
  width: 50%;
}
.myorders .myorder_body .order_card .order_data > div.order_buttons > div {
  width: 136px;
  margin: auto;
  color: #d8d8d8;
  padding-block: 13px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.myorders .myorder_body .order_card .order_data > div:not(.order_buttons) > div:first-child {
  margin-bottom: 10px;
}
.myorders .myorder_body .order_card .order_data > div.order_titleandcount > div:not(:first-child) {
  margin-top: 26px !important;
}
@media screen and (max-width: 1040px) {
  .myorders .myorder_body .order_card .order_data > div.order_titleandcount {
    order: 1;
    margin-bottom: 27px;
  }
  .myorders .myorder_body .order_card .order_data > div.order_total {
    order: 3;
  }
  .myorders .myorder_body .order_card .order_data > div.order_status {
    order: 2;
    margin-bottom: 27px;
  }
  .myorders .myorder_body .order_card .order_data > div.order_buttons {
    order: 4;
  }
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_addedit {
  display: flex;
  width: 75%;
  flex-wrap: wrap;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_addedit > div {
  width: 33.33%;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_addedit > div .heading {
  font-weight: 700;
  margin-bottom: 9px;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_addedit > div.order_photo, .myorders .myorder_body .order_card .order_data.orderaddedit .order_addedit > div.order_notes {
  margin-top: 60px;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_addedit > div.order_photo .photo_group > div {
  display: flex;
  margin-bottom: 16px;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_addedit > div.order_photo .photo_group > div img {
  width: 100%;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_addedit > div.order_photo .photo_group > div .user_photo {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 80px;
  box-shadow: 0 0 1px 1px #ffffff;
  border-radius: 6px;
  margin-right: 12px;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_addedit > div.order_photo .photo_group .add_img #addphoto_orderaddedit {
  display: none;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_addedit > div.order_notes {
  width: 45%;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_addedit > div.order_notes textarea {
  background-color: transparent;
  resize: none;
  width: 100%;
  color: #ffffff;
  border-radius: 6px;
  border: 1px solid #ffffff;
  padding: 13px 17px;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_addedit > div.order_notes .notes_box {
  display: flex;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_addedit > div.order_notes .notes_box > img {
  align-self: flex-start;
  margin-left: 12px;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_addedit .order_titleandcount > div:not(:last-child) {
  margin-bottom: 26px;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_buttons {
  width: 25%;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_buttons > div:not(:last-child) {
  margin-bottom: 6px;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_buttons .btn_save,
.myorders .myorder_body .order_card .order_data.orderaddedit .order_buttons .btn_receipt,
.myorders .myorder_body .order_card .order_data.orderaddedit .order_buttons .btn_back,
.myorders .myorder_body .order_card .order_data.orderaddedit .order_buttons .btn_cancelpickup {
  font-weight: 500;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_buttons .btn_save {
  background-color: #ffffff;
  color: #000;
}
.myorders .myorder_body .order_card .order_data.orderaddedit .order_buttons .btn_receipt,
.myorders .myorder_body .order_card .order_data.orderaddedit .order_buttons .btn_back,
.myorders .myorder_body .order_card .order_data.orderaddedit .order_buttons .btn_cancelpickup {
  border: 1px solid #d8d8d8;
  color: #d8d8d8;
}
@media screen and (max-width: 1040px) {
  .myorders .add_edit_order {
    margin-bottom: 0 !important;
  }
  .myorders .add_edit_order .order_data {
    display: block !important;
  }
  .myorders .add_edit_order .order_data .order_addedit {
    width: 100% !important;
    flex-wrap: wrap !important;
  }
  .myorders .add_edit_order .order_data .order_addedit > div.order_total, .myorders .add_edit_order .order_data .order_addedit > div.order_photo, .myorders .add_edit_order .order_data .order_addedit > div.order_notes {
    width: 100% !important;
  }
  .myorders .add_edit_order .order_data .order_addedit > div.order_titleandcount, .myorders .add_edit_order .order_data .order_addedit > div.order_status {
    width: 50% !important;
  }
  .myorders .add_edit_order .order_data .order_addedit > div.order_titleandcount {
    order: 1;
  }
  .myorders .add_edit_order .order_data .order_addedit > div.order_status {
    order: 2;
  }
  .myorders .add_edit_order .order_data .order_addedit > div.order_total {
    order: 3;
  }
  .myorders .add_edit_order .order_data .order_addedit > div.order_photo {
    order: 4;
  }
  .myorders .add_edit_order .order_data .order_addedit > div.order_photo .heading {
    font-size: 16px;
    line-height: 21px;
  }
  .myorders .add_edit_order .order_data .order_addedit > div.order_photo .photo_group {
    display: flex;
    flex-wrap: wrap;
  }
  .myorders .add_edit_order .order_data .order_addedit > div.order_photo .photo_group > div:not(:last-child) {
    width: 50%;
  }
  .myorders .add_edit_order .order_data .order_addedit > div.order_notes {
    order: 5;
    margin-top: 40px !important;
    margin-bottom: 34px !important;
  }
  .myorders .add_edit_order .order_data .order_addedit > div.order_notes .heading {
    font-size: 16px;
    line-height: 21px;
  }
  .myorders .add_edit_order .order_data .order_buttons {
    width: 100% !important;
  }
  .myorders .add_edit_order .order_data .order_buttons > div {
    width: 100% !important;
  }
}

.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 44px 0 44px;
  letter-spacing: 0.819253px;
}
.profile input[readonly] {
  pointer-events: none;
}
.profile .loading {
  text-align: center;
}
@media screen and (max-width: 1040px) {
  .profile {
    display: block;
    text-align: left;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 112px);
    overflow-y: auto;
    padding: 185px 44px 0px 44px;
  }
  .profile > .page_title {
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
  }
  .profile > .page_title ~ div {
    flex-grow: 1;
    width: 100%;
    background-color: #000;
  }
}
.profile .profile_body {
  margin: 112px 0 0 0;
  padding: 104px 0 85px 0;
  height: calc(100vh - 112px);
  width: 540px;
  font-weight: 700;
  letter-spacing: 0.9975px;
  padding: 85px 0 85px 0;
}
@media screen and (max-width: 1040px) {
  .profile .profile_body {
    margin: 0;
    min-height: 100%;
    height: unset;
    overflow: unset;
    padding: 0px 0 85px 0;
  }
}
.profile .profile_body .mt-18 {
  margin: 18px 0 0 0;
}
@media screen and (max-width: 1040px) {
  .profile .profile_body .mt-18 {
    margin: 16px 0 0 0;
  }
}
.profile .profile_body .mt-25 {
  margin: 25px 0 0 0;
}
.profile .profile_body > div input[type=text],
.profile .profile_body > div input[type=password] {
  font-size: 16px;
  padding: 12px 0 12px 0;
  width: 100%;
  background: transparent;
  border: 0;
  color: #d8d8d8;
  border-bottom: 1px solid #f2f2f2;
  font-weight: 400;
  letter-spacing: 0.819253px;
}
.profile .profile_body > div input[type=text]:focus-visible,
.profile .profile_body > div input[type=password]:focus-visible {
  outline: 0;
}
.profile .profile_body > div .adress_flex,
.profile .profile_body > div .card_flex {
  display: flex;
}
.profile .profile_body > div .adress_flex input:first-child,
.profile .profile_body > div .card_flex input:first-child {
  margin: 0 13px 0 0;
}
.profile .profile_body > div .adress_flex input:last-child,
.profile .profile_body > div .card_flex input:last-child {
  margin: 0 13px 0 0;
}
.profile .profile_body > div .address_doorman_checkbox {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1040px) {
  .profile .profile_body > div .address_doorman_checkbox {
    flex-direction: column-reverse;
  }
  .profile .profile_body > div .address_doorman_checkbox .doorman_flex {
    display: flex;
    align-items: center;
  }
  .profile .profile_body > div .address_doorman_checkbox .doorman_flex > * {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .profile .profile_body > div .address_doorman_checkbox .doorman_flex label {
    font-size: 14px;
    font-weight: 400 !important;
    margin: 0 !important;
  }
}
.profile .profile_body > div .doorman_flex {
  display: flex;
  font-family: 400;
  align-items: center;
}
.profile .profile_body > div .doorman_flex > div:first-child {
  margin: 0 13px 0 0;
}
@media screen and (max-width: 1040px) {
  .profile .profile_body > div .doorman_flex {
    display: block;
  }
  .profile .profile_body > div .doorman_flex label {
    width: 100%;
  }
  .profile .profile_body > div .doorman_flex label:last-child {
    margin: 15px 0 0 0;
  }
}
.profile .profile_body .short_intro {
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile .profile_body .short_intro .short_img {
  display: flex;
  border-radius: 55px;
  position: relative;
}
.profile .profile_body .short_intro .short_img > .img {
  border: 2px solid #ffffff;
  border-radius: 55px;
  height: 110px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.profile .profile_body .short_intro .short_img > .img img {
  max-width: 100%;
}
@media screen and (max-width: 1040px) {
  .profile .profile_body .short_intro .short_img > .img {
    width: 94px;
    height: 94px;
  }
}
.profile .profile_body .short_intro .short_img .change_profile_image {
  position: absolute;
  height: 24px;
  width: 24px;
  bottom: 1.8px;
  right: 1.8px;
  background-color: #ffffff;
  padding: 4px;
  box-shadow: 0 0 1px 1.8px #ffffff;
  border-radius: 5px;
}
.profile .profile_body .short_intro .short_img .change_profile_image img {
  border-radius: 0;
  width: 100%;
}
.profile .profile_body .short_intro .short_txt {
  margin: 31px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.profile .profile_body .short_intro .short_txt .short_name {
  font-weight: 700;
}
.profile .profile_body .short_intro .short_txt .short_name .short_btns {
  display: flex;
  margin: 17px 0 0 0;
}
.profile .profile_body .short_intro .short_txt .short_name .short_btns > div {
  min-width: 97px;
  margin: 0 16px 0 0;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
}
@media screen and (max-width: 1040px) {
  .profile .profile_body .short_intro .short_txt .short_name .short_btns > div {
    min-width: unset;
    width: 100%;
    margin: 0;
  }
  .profile .profile_body .short_intro .short_txt .short_name .short_btns > div:first-child {
    margin: 0 9px 0 0;
  }
}
.profile .profile_body .personal_details {
  margin: 64px 0 0 0;
}
@media screen and (max-width: 1040px) {
  .profile .profile_body .personal_details {
    margin: 48px 0 0 0;
  }
}
.profile .profile_body .adress_details {
  margin: 120px 0 0 0;
}
.profile .profile_body .adress_details > div:first-child {
  display: flex;
  justify-content: space-between;
}
.profile .profile_body .adress_details > div:first-child .address_details_action {
  display: flex;
  align-items: center;
}
.profile .profile_body .adress_details > div:first-child .address_details_action img {
  height: 24px;
}
.profile .profile_body .adress_details > div:first-child .address_details_action img.pencil_img {
  height: 20px;
  margin: 0 20px 0 0;
}
.profile .profile_body .adress_details > div:first-child .address_details_action img.delete_add {
  margin: 0 0 0 20px;
}
@media screen and (max-width: 1040px) {
  .profile .profile_body .adress_details {
    margin: 80px 0 0 0;
  }
}
.profile .profile_body .payment_details {
  margin: 120px 0 0 0;
}
@media screen and (max-width: 1040px) {
  .profile .profile_body .payment_details {
    margin: 80px 0 0 0;
  }
}
.profile .profile_body .logout_div {
  margin: 93px 0 0 0;
}
.profile .profile_body .logout_div > div {
  width: 136px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 6px;
}
.profile .profile_body .for_edit {
  position: relative;
}
.profile .profile_body .for_edit .edit_details {
  height: 15px;
  position: absolute;
  right: 0;
  bottom: 19px;
}
.profile .profile_body .address_slider .slick-prev:before {
  color: transparent;
  background-image: url("../img/profile/slider_prev_arrow.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
}
.profile .profile_body .address_slider .slick-next:before {
  color: transparent;
  background-image: url("../img/profile/slider_next_arrow.svg");
  background-repeat: no-repeat;
  background-position: 100% 0;
}
@media screen and (max-width: 1040px) {
  .profile .profile_body .address_slider .address_slider_inp_group {
    display: flex;
  }
  .profile .profile_body .address_slider .address_slider_inp_group > input:first-child {
    margin: 0 8px 0 0;
  }
  .profile .profile_body .address_slider .address_slider_inp_group > input:last-child {
    margin: 0 0 0 8px;
  }
}
.profile .edit_details {
  cursor: pointer;
}
.profile .error {
  padding: 5px 10px;
  border: 1px solid #ff2e2f;
  margin: 6px 0 9px 0;
  border-radius: 5px;
}
.profile .profile_modal {
  position: fixed;
  inset: 0;
  background: rgba(255, 255, 255, 0.2);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile .profile_modal .profile_modal_juliette {
  display: none;
}
@media screen and (max-width: 1040px) {
  .profile .profile_modal .profile_modal_juliette {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 67px 0 0 0;
    text-align: center;
  }
  .profile .profile_modal .profile_modal_juliette img {
    height: 21px;
    margin: 5px 0 5px 0;
  }
}
.profile .profile_modal #changephoto {
  display: none;
}
.profile .profile_modal > div {
  background-color: #000;
  border: 1px solid #000;
}
.profile .profile_modal > div *[btn_type] {
  cursor: pointer;
  padding: 13px 0 13px 0;
  height: 44px;
  text-align: center;
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}
.profile .profile_modal > div *[btn_type=type1] {
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
}
.profile .profile_modal > div *[btn_type=type2] {
  color: #d8d8d8;
  background-color: transparent;
  border: 1px solid #d8d8d8;
}
.profile .profile_modal > div *[btn_type=type3] {
  color: #000;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}
.profile .profile_modal > div.profile_modal_common_card {
  width: 421px;
  height: 668px;
  border-radius: 12px;
  padding: 56px 66px 57px 67px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1040px) {
  .profile .profile_modal > div.profile_modal_common_card {
    overflow-y: scroll;
    padding: 139px 66px 118px 67px;
  }
}
.profile .profile_modal > div.profile_modal_common_card .card_heading {
  font-size: 40px;
  line-height: 40px;
  margin: 0 0 47px 0;
}
.profile .profile_modal > div.profile_modal_common_card .card_title {
  font-size: 14px;
}
.profile .profile_modal > div.profile_modal_common_card .card_title.mt-34 {
  margin: 34px 0 0 0;
}
.profile .profile_modal > div.profile_modal_common_card .card_input {
  margin: 8px 0 0 0;
  position: relative;
}
.profile .profile_modal > div.profile_modal_common_card .card_input input {
  width: 100%;
  padding: 12px 0 12px 0;
  font-size: 14px;
  line-height: 18px;
  background: transparent;
  color: #ffffff;
  border: 0;
  outline: 0;
  border-bottom: 1px solid #f2f2f2;
}
.profile .profile_modal > div.profile_modal_common_card .card_input input::-moz-placeholder {
  color: #ffffff;
}
.profile .profile_modal > div.profile_modal_common_card .card_input input:-ms-input-placeholder {
  color: #ffffff;
}
.profile .profile_modal > div.profile_modal_common_card .card_input input::placeholder {
  color: #ffffff;
}
.profile .profile_modal > div.profile_modal_common_card .card_input .password_img {
  position: absolute;
  right: 0;
  bottom: 12px;
}
.profile .profile_modal > div.profile_modal_common_card .card_input.doorman_checkbox {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 30px 0 0 0;
}
.profile .profile_modal > div.profile_modal_common_card .card_input.doorman_checkbox > div {
  margin: 0 13px 0 0;
}
.profile .profile_modal > div.profile_modal_common_card .btn_group {
  display: flex;
  padding: 20px 0 0 0;
}
.profile .profile_modal > div.profile_modal_common_card .btn_group.btn_group_vertical {
  flex-direction: column;
}
.profile .profile_modal > div.profile_modal_common_card .btn_group.btn_group_vertical > div:not(:last-child) {
  margin: 0 0 6px 0;
}
.profile .profile_modal > div.profile_modal_common_card .btn_group.btn_group_horizontal > div {
  width: 50%;
}
.profile .profile_modal > div.profile_modal_common_card .btn_group.btn_group_horizontal > div:first-child {
  margin: 0 8px 0 0;
}
.profile .profile_modal > div.profile_modal_common_card .btn_group.btn_group_horizontal > div:last-child {
  margin: 0 0 0 8px;
}
.profile .profile_modal > div.profile_modal_common_card .card_txt14 {
  font-size: 14px;
  margin: 0 0 20px 0;
}
.profile .profile_modal > div.profile_modal_common_card .margin_bottom_auto {
  margin: 0 0 auto 0;
}
.profile .profile_modal > div.profile_delete_modal_common_card {
  width: 768px;
  height: 468px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1040px) {
  .profile .profile_modal > div.profile_delete_modal_common_card {
    width: 301px;
    height: 269px;
    padding: 48px 28px 40px 28px;
  }
}
.profile .profile_modal > div.profile_delete_modal_common_card .card_question {
  font-size: 26px;
  line-height: 34px;
  font-weight: 500;
  max-width: 403px;
  margin: 0 0 38px 0;
}
@media screen and (max-width: 1040px) {
  .profile .profile_modal > div.profile_delete_modal_common_card .card_question {
    font-size: 18px;
    line-height: 23px;
  }
}
.profile .profile_modal > div.profile_delete_modal_common_card .btn_group {
  display: flex;
}
.profile .profile_modal > div.profile_delete_modal_common_card .btn_group > div {
  width: 136px;
}
.profile .profile_modal > div.profile_delete_modal_common_card .btn_group > div:first-child {
  margin: 0 3.15px 0 0;
}
.profile .profile_modal > div.profile_delete_modal_common_card .btn_group > div:last-child {
  margin: 0 3.15px 0 0;
}
@media screen and (max-width: 1040px) {
  .profile .profile_modal > div.profile_delete_modal_common_card .btn_group {
    flex-direction: column-reverse;
  }
  .profile .profile_modal > div.profile_delete_modal_common_card .btn_group > div:last-child {
    margin: 0 0 7px 0;
  }
}
@media screen and (max-width: 1040px) {
  .profile .profile_modal > div:not(.profile_modal_juliette):not(.profile_delete_modal_common_card) {
    height: 100% !important;
    width: 100% !important;
    border-radius: 0 !important;
  }
}
.profile .profile_modal .changeprofilepicture {
  height: 311px;
  width: 505px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile .profile_modal .changeprofilepicture div[btn_type] {
  width: 288px;
}
.profile .profile_modal .changeprofilepicture > label {
  margin-bottom: 12px;
  margin: 0 0 12px 0;
}

.pricelist {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  letter-spacing: 0.9975px;
  height: 100vh;
  padding: 131px 44px 44px;
  /* Handle */
  /* Handle on hover */
}
@media screen and (max-width: 1040px) {
  .pricelist {
    display: block;
    text-align: left;
    flex-direction: column;
    align-items: center;
    padding: 161px 44px 0 44px;
  }
  .pricelist > .page_title {
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
  }
  .pricelist > .page_title ~ div {
    flex-grow: 1;
    width: 100%;
  }
}
.pricelist ::-webkit-scrollbar {
  width: 0px;
  height: 2px;
}
.pricelist ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.514);
  border-radius: 10px;
}
.pricelist ::-webkit-scrollbar-thumb {
  background: #818181;
  border-radius: 10px;
}
.pricelist ::-webkit-scrollbar-thumb:hover {
  background: #8d8d8d;
}
.pricelist .pricelist_main_body {
  width: 100%;
}
.pricelist .pricelist_body {
  margin-inline: auto;
  -moz-column-count: 3;
       column-count: 3;
  margin: 42px 100px 0 50px;
}
@media screen and (max-width: 1040px) {
  .pricelist .pricelist_body {
    width: 100%;
    -moz-column-count: unset;
         column-count: unset;
    -moz-column-gap: unset;
         column-gap: unset;
    margin: 42px 0 0 0 !important;
  }
}
.pricelist .pricelist_body .pricelist_body_height {
  margin: 42px 112px 0 0;
}
@media screen and (max-width: 1040px) {
  .pricelist .pricelist_body .pricelist_body_height {
    margin: 42px 0 0 0 !important;
  }
}
.pricelist .pricelist_body .pricelist_body_height:first-child {
  margin: 42px 112px 0 auto;
}
.pricelist .pricelist_body .pricelist_body_height:last-child {
  margin: 42px auto 0 0;
}
.pricelist .pricelist_body .pricelist_body_height > div:not(:last-child) {
  margin: 0 0 69px 0;
}
.pricelist .pricelist_body .body_colm {
  --gap-between-colm: 200px;
  width: 207px;
  display: grid;
  grid-template-rows: auto;
  margin: 0 auto 30px auto;
  -moz-column-break-inside: avoid;
       break-inside: avoid;
}
.pricelist .pricelist_body .body_colm > div {
  width: 207px;
}
@media screen and (max-width: 1040px) {
  .pricelist .pricelist_body .body_colm {
    display: block;
    width: 100%;
    -moz-column-break-inside: unset;
         break-inside: unset;
  }
  .pricelist .pricelist_body .body_colm > div {
    width: 100%;
  }
  .pricelist .pricelist_body .body_colm:not(:first-child) {
    margin: 70px 0 0 0 !important;
  }
}
.pricelist .pricelist_body .body_colm .body_colm_title {
  margin: 0 0 36px 0;
}
@media screen and (max-width: 1040px) {
  .pricelist .pricelist_body {
    display: block;
  }
  .pricelist .pricelist_body .body_colm {
    width: 100%;
    margin: 0;
  }
  .pricelist .pricelist_body .body_colm.colm2 {
    margin: 40px 0 0 0;
  }
  .pricelist .pricelist_body .body_colm .body_colm_title {
    margin: 0 0 24px 0;
  }
}
.pricelist .pricelist_body .accordian_body {
  cursor: pointer;
  height: 22px;
  overflow: hidden;
  margin: 0 0 16px 0;
  transition: height 300ms linear;
}
@media screen and (max-width: 1040px) {
  .pricelist .pricelist_body .accordian_body {
    margin: 0 0 16px 0;
  }
}
.pricelist .pricelist_body .accordian_body .accordian_head {
  display: flex;
  justify-content: space-between;
}
.pricelist .pricelist_body .accordian_body .accordian_head .arrowdown {
  transition: all 300ms linear;
}
.pricelist .pricelist_body .accordian_body .accordian_content > div {
  display: flex;
  justify-content: space-between;
  margin: 0 0 12px 0;
  font-size: 14px !important;
  line-height: 18px !important;
}
.pricelist .pricelist_body .accordian_body .accordian_content > div:first-child {
  margin: 10px 0 12px 0;
}
.pricelist .pricelist_body .accordian_body.active .arrowdown {
  transform: rotate(180deg);
}

.faq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 44px 0 44px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://julietteimages.s3.amazonaws.com/systemimages/faq-min.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.faq > .retain_navbar {
  height: 118px;
}
@media screen and (max-width: 1040px) {
  .faq {
    text-align: left;
    padding: 161px 44px 0 44px;
    flex-direction: column;
    align-items: center;
  }
  .faq > .retain_navbar {
    height: 0px;
  }
  .faq .page_title {
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
  }
  .faq .page_title ~ div {
    flex-grow: 1;
    width: 100%;
  }
}
.faq .faq_body {
  max-height: calc(100vh - 118px);
  overflow-y: scroll;
  padding: 135px 0 0 0;
}
@media screen and (max-width: 1040px) {
  .faq .faq_body {
    padding: 0;
    max-height: calc(100vh - 150px);
  }
}
.faq .faq_body .faq_block {
  margin: 0 0 60px 0;
}
.faq .faq_body .faq_block > div {
  width: 800px;
  margin: 0 0 20px 0;
  display: flex;
}
.faq .faq_body .faq_block > div > div:first-child {
  margin: 0 10px 0 0;
}
.faq .faq_body .faq_block > div .a_btn_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1040px) {
  .faq .faq_body .faq_block > div .a_btn_group {
    display: block;
  }
}
.faq .faq_body .faq_block > div p {
  margin: 0;
  margin: 0 0 20px 0;
}
.faq .faq_body .faq_block > div p strong {
  font-size: 20px;
}
.faq .faq_body .faq_block > div p a {
  color: #000;
  font-weight: 700;
  text-decoration: none;
}
.faq .faq_body .faq_block > div p[type=a_btn] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #fff;
  padding: 10px 20px;
  border-radius: 4px;
}
.faq .faq_body .faq_block > div p[type=a_btn] > a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.faq .faq_body .faq_block > div p[type=a_btn] > a svg {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
@media screen and (max-width: 1040px) {
  .faq .faq_body .faq_block > div {
    width: 100%;
  }
}
.faq .faq_body .faq_block > div.f18 {
  font-weight: 700;
}
.faq .faq_body .faq_block > div.f16 p {
  font-weight: 400;
}
@media screen and (max-width: 1040px) {
  .faq .faq_body .faq_block > .f18,
.faq .faq_body .faq_block > .f14 {
    font-size: 14px !important;
    line-height: 18px !important;
  }
}

.faq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 44px 0 44px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://julietteimages.s3.amazonaws.com/systemimages/faq-min.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.faq > .retain_navbar {
  height: 118px;
}
@media screen and (max-width: 1040px) {
  .faq {
    text-align: left;
    padding: 161px 44px 0 44px;
    flex-direction: column;
    align-items: center;
  }
  .faq > .retain_navbar {
    height: 0px;
  }
  .faq .page_title {
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
  }
  .faq .page_title ~ div {
    flex-grow: 1;
    width: 100%;
  }
}
.faq .faq_body {
  max-height: calc(100vh - 118px);
  overflow-y: scroll;
  padding: 135px 0 0 0;
}
@media screen and (max-width: 1040px) {
  .faq .faq_body {
    padding: 0;
    max-height: calc(100vh - 150px);
  }
}
.faq .faq_body .preview_body {
  color: #fff;
  margin: 0 0 60px 0;
  width: 800px;
}
@media screen and (max-width: 1040px) {
  .faq .faq_body .preview_body {
    width: 100%;
  }
}
.faq .faq_body .preview_body h1,
.faq .faq_body .preview_body h2,
.faq .faq_body .preview_body h3 {
  line-height: 1;
  margin: 0;
}
.faq .faq_body .preview_body ul,
.faq .faq_body .preview_body ol {
  margin: 10px 0 10px 0;
}
.faq .faq_body .preview_body > div {
  display: flex;
}
.faq .faq_body .preview_body > div > div:first-child {
  width: 20px;
  text-align: right;
  margin: 0 10px 0 0;
}
.faq .faq_body .preview_body > div > div:last-child {
  width: calc(100% - 20px);
  color: var(--white) !important;
}
.faq .faq_body .preview_body > div > div:last-child .rte .rte_editor {
  margin: 0;
  padding: 0;
  border: 0;
}
.faq .faq_body .preview_body .question {
  font-weight: 600;
  margin: 0 0 20px 0;
  text-transform: uppercase;
}
.faq .faq_body .preview_body a {
  all: unset;
}
.faq .faq_body .preview_body a > div {
  font-weight: 800 !important;
  color: #000;
}
.faq .faq_body .preview_body .element-link {
  background: var(--white);
}
.faq .faq_body .preview_body strong {
  font-size: 20px;
  font-weight: bold;
}
.faq .faq_body .preview_body strong span {
  font-size: 20px;
  font-weight: bold;
}
.faq .faq_body .preview_body .links_group {
  display: flex;
  flex-direction: column;
}
.faq .faq_body .preview_body .links_group .link_btn_items {
  padding: 10px 20px;
  background: #fff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 20px 0 0 30px;
  border-radius: 4px;
  color: #000;
  font-weight: 700;
}
.faq .faq_body .preview_body .links_group .link_btn_items a {
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.faq .faq_body .preview_body .links_group .link_btn_items a .link_img {
  height: 20px;
  width: 20px;
}

.myordertemp {
  padding: 112px 0 0 0;
  z-index: 0;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1040px) {
  .myordertemp {
    padding: 149px 0 0 0;
  }
}
.myordertemp .page_title {
  justify-content: flex-start;
}
@media screen and (max-width: 1040px) {
  .myordertemp .page_title {
    padding: 0 0 0 30px;
  }
}
.myordertemp > div {
  max-height: calc(100vh - 121px);
  overflow-y: auto;
  width: 874px;
  margin: 0 auto 0 auto;
  padding: 50px 0 162px 0;
}
@media screen and (max-width: 1040px) {
  .myordertemp > div {
    margin: 0 0 auto 0;
    max-height: calc(100vh - 149px);
    padding: 0 0 50px 0;
  }
}
.myordertemp > div .order_card {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}
.myordertemp > div .order_card:not(:last-child) {
  margin-bottom: 68px;
}
.myordertemp > div .order_card .date {
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
  margin: 0 50px 0 0;
  width: 100% !important;
}
.myordertemp > div .order_card .string_showing {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-self: flex-start;
}
@media screen and (max-width: 1040px) {
  .myordertemp > div .order_card .string_showing {
    margin-bottom: 20px;
  }
}
.myordertemp > div .order_card .title {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}
.myordertemp > div .order_card .data_flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.myordertemp > div .order_card .data_flex > div {
  width: 25%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.myordertemp > div .order_card .data_flex .action_btn {
  cursor: pointer !important;
  border: 1px solid #d8d8d8;
  max-width: 136px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
@media screen and (max-width: 1040px) {
  .myordertemp > div .order_card .data_flex {
    flex-wrap: wrap;
    width: calc(100% - 80px);
    margin: 0 auto 0 auto;
  }
  .myordertemp > div .order_card .data_flex .date {
    width: 100%;
    margin: 0 0 10px 0;
  }
}
@media screen and (max-width: 1040px) and (max-width: 1040px) {
  .myordertemp > div .order_card .data_flex .date {
    margin: 0 0 12px 0;
  }
}
@media screen and (max-width: 1040px) {
  .myordertemp > div .order_card .data_flex > div:not(:first-child) {
    width: 50%;
  }
}
.myordertemp .add_edit_order {
  max-height: unset;
  position: fixed;
  left: 0;
  padding: 113px 140px 20px 30px !important;
  margin: 0 auto 0 0;
  top: 0px;
  background: #000;
  width: calc(100vw - 0px);
  height: calc(100vh - 0px);
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #fff;
  z-index: 1;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  border-radius: 10px;
  overflow: scroll;
}
@media screen and (max-width: 1040px) {
  .myordertemp .add_edit_order {
    padding: 149px 30px 60px 30px !important;
  }
}
.myordertemp .add_edit_order .header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.myordertemp .add_edit_order .header .close {
  transform: translate(5px, -5px);
  cursor: pointer;
  height: 20px;
  width: 20px;
  background: #b9b9b9;
  -webkit-clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
          clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
}
.myordertemp .add_edit_order .body {
  background: #000;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: flex-start;
  height: 100%;
}
.myordertemp .add_edit_order .body > div {
  width: calc(33.3333333333% - 20px);
}
.myordertemp .add_edit_order .body .main_card {
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  align-self: stretch;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.myordertemp .add_edit_order .body .main_card .name {
  font-size: 30px;
  line-height: 35px;
  margin: 0 0 14px 0;
}
.myordertemp .add_edit_order .body .main_card .add {
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 4px 0;
}
.myordertemp .add_edit_order .body .main_card .mobile {
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 4px 0;
}
.myordertemp .add_edit_order .body .main_card .card_last4 {
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 0 0;
}
.myordertemp .add_edit_order .body .main_card .item_collection {
  margin: 20px 0 20px 0;
  flex-grow: 1;
  overflow-y: scroll;
}
.myordertemp .add_edit_order .body .main_card .item_collection .item {
  display: flex;
  padding-right: 19px;
  position: relative;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 6px;
  padding: 14px 17px 13px 16px;
  margin: 0 0 10px 0;
  flex-wrap: wrap;
}
.myordertemp .add_edit_order .body .main_card .item_collection .item .item_data {
  width: calc(100% - 100px);
}
.myordertemp .add_edit_order .body .main_card .item_collection .item .item_data .item_name {
  font-size: 16px;
  line-height: 17px;
}
.myordertemp .add_edit_order .body .main_card .item_collection .item .item_data .item_name > div:first-child {
  color: #b9b9b9;
  font-size: 9px;
  line-height: 12px;
}
.myordertemp .add_edit_order .body .main_card .item_collection .item .item_data > .weight {
  font-size: 12px;
  line-height: 14px;
  margin: 6px 0 0 0;
}
.myordertemp .add_edit_order .body .main_card .item_collection .item .item_price {
  width: 100px;
  text-align: right;
}
.myordertemp .add_edit_order .body .main_card .item_collection .item .item_addon_group {
  width: 100%;
}
.myordertemp .add_edit_order .body .main_card .item_collection .item .item_addon_group .card_total {
  border-top: 1px solid #b9b9b9;
  margin: 10px 0 0 0;
  padding: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
}
.myordertemp .add_edit_order .body .main_card .item_collection .item .item_addon_group > .item_addonitem {
  margin: 10px 0 0 0;
  display: flex;
}
.myordertemp .add_edit_order .body .main_card .item_collection .item .item_addon_group > .item_addonitem .addon_list {
  width: 30px;
}
.myordertemp .add_edit_order .body .main_card .item_collection .item .item_addon_group > .item_addonitem .addon_name {
  width: calc(100% - 30px - 100px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.myordertemp .add_edit_order .body .main_card .item_collection .item .item_addon_group > .item_addonitem .addon_name > div:first-child {
  font-size: 9px;
  line-height: 12px;
  color: #b9b9b9;
}
.myordertemp .add_edit_order .body .main_card .item_collection .item .item_addon_group > .item_addonitem .addon_price {
  width: 100px;
  text-align: right;
}
.myordertemp .add_edit_order .body .main_card .sub_total,
.myordertemp .add_edit_order .body .main_card .total,
.myordertemp .add_edit_order .body .main_card .discount {
  display: flex;
  font-size: 16px;
  line-height: 18px;
}
.myordertemp .add_edit_order .body .main_card .sub_total > div:first-child,
.myordertemp .add_edit_order .body .main_card .total > div:first-child,
.myordertemp .add_edit_order .body .main_card .discount > div:first-child {
  flex-grow: 1;
}
.myordertemp .add_edit_order .body .main_card .discount {
  font-size: 14px;
  line-height: 15px;
}
.myordertemp .add_edit_order .body .main_card .total {
  margin: 13px 0 0 0;
  padding: 16px 0 0 0;
  border-top: 1px solid #ffffff;
}
.myordertemp .add_edit_order .body .details_card {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}
.myordertemp .add_edit_order .body .details_card > div {
  margin: 20px 0 20px 0;
}
.myordertemp .add_edit_order .body .details_card[single=true] {
  width: 300px !important;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
}
.myordertemp .add_edit_order .body .details_card .date {
  font-size: 22px;
  line-height: 29px;
  margin: 0 0 10px 0;
  color: #d8d8d8;
}
.myordertemp .add_edit_order .body .details_card .title {
  font-size: 18px;
  font-weight: 700;
}
.myordertemp .add_edit_order .body .details_card .option_group {
  font-size: 14px;
}
.myordertemp .add_edit_order .body .details_card .option_group ol {
  padding: 0 0 0 20px;
  text-align: left;
}
.myordertemp .add_edit_order .body .details_card .btn_group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0 0 0;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.myordertemp .add_edit_order .body .details_card .btn_group .btn {
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  border: 1px solid #737373;
  padding: 10px 0;
  border-radius: 4px;
  text-align: center;
}
.myordertemp .add_edit_order .body .details_card .btn_group .btn.a {
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.myordertemp .add_edit_order .body .details_card .btn_group .btn.a a {
  all: unset;
  width: 100%;
  padding: 10px 0;
}
.myordertemp .add_edit_order .body .photos_notes_card {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.myordertemp .add_edit_order .body .photos_notes_card .image_group {
  flex-grow: 1;
  -moz-column-count: 3;
       column-count: 3;
  -moz-column-width: 150px;
       column-width: 150px;
  text-align: center;
  overflow-y: scroll;
  border: 1px solid #d8d8d8;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0 0 0;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
.myordertemp .add_edit_order .body .photos_notes_card .image_group > div {
  max-width: 50%;
  min-width: 150px;
  text-align: center;
  margin: 0 auto 0 auto;
}
.myordertemp .add_edit_order .body .photos_notes_card .image_group > div img {
  padding: 5px;
  border: 1px solid #737373;
  border-radius: 5px;
  max-width: 100%;
  height: auto;
  max-height: 150px;
}
.myordertemp .add_edit_order .body .photos_notes_card .notes {
  flex-grow: 1;
  overflow-y: scroll;
  line-height: 1;
  border: 1px solid #d8d8d8;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0 10px 0;
}
.myordertemp .add_edit_order .card_title {
  padding: 0 !important;
  font-size: 20px;
  font-weight: 500;
}
.myordertemp .add_edit_order .details_card .card_title {
  margin: 0 0 20px 0 !important;
}
.myordertemp .add_edit_order.active {
  transform: translateX(0%);
}
.myordertemp .add_edit_order.active .item {
  transform: translateY(100px);
  opacity: 0;
}
@-webkit-keyframes to-top {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes to-top {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@media screen and (max-width: 1040px) {
  .myordertemp .add_edit_order {
    border: 1px solid transparent;
    overflow-y: scroll;
    margin: 0;
    width: 100vw;
  }
  .myordertemp .add_edit_order.active {
    box-shadow: 0 0 0 200px transparent;
    transform: translateX(0%);
  }
  .myordertemp .add_edit_order .body {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    flex-direction: column;
  }
  .myordertemp .add_edit_order .body > div {
    width: 100%;
  }
  .myordertemp .add_edit_order .body > div:first-child {
    order: 1;
  }
  .myordertemp .add_edit_order .body > div:nth-child(2) {
    order: 3;
  }
  .myordertemp .add_edit_order .body > div:last-child {
    order: 2;
  }
}
.myordertemp .order_loader {
  background: rgba(174, 174, 174, 0.4901960784);
  position: fixed;
  inset: 0;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 100;
  margin: 0;
  padding: 0;
  max-height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}
.myordertemp .order_loader > div {
  background: #000;
  padding: 20px 40px;
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
  line-height: 1;
}
.myordertemp .order_loader > div .dot_group {
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
  margin: 0 0 0 5px;
}
.myordertemp .order_loader > div .dot_group > .dots {
  height: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #fff;
}
@-webkit-keyframes go1 {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes go1 {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.myordertemp .order_loader > div .dot_group > .dots:nth-child(1) {
  -webkit-animation: go1 1s linear infinite;
          animation: go1 1s linear infinite;
}
.myordertemp .order_loader > div .dot_group > .dots:nth-child(2) {
  -webkit-animation: go1 1s 0.3s linear infinite;
          animation: go1 1s 0.3s linear infinite;
}
.myordertemp .order_loader > div .dot_group > .dots:nth-child(3) {
  -webkit-animation: go1 1s 0.6s linear infinite;
          animation: go1 1s 0.6s linear infinite;
}
.myordertemp .order_loader > div .dot_group > .dots:nth-child(4) {
  -webkit-animation: go1 1s 0.9s linear infinite;
          animation: go1 1s 0.9s linear infinite;
}

.notfound {
  background-image: url("../img/notfound_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.notfound .txt1 {
  font-size: 80px;
  line-height: 104.16px;
}
@media screen and (max-width: 1040px) {
  .notfound .txt1 {
    line-height: 90px;
  }
}
.notfound .txt2 {
  font-size: 26px;
  line-height: 33.85px;
}
@media screen and (max-width: 1040px) {
  .notfound .txt2 {
    font-size: 14px;
    line-height: 18px;
  }
}
.notfound .homepage {
  margin: 36px 0 0 0;
  background-color: #ffffff;
  color: #000;
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
  width: 288px;
  padding: 13px 0px 13px 0px;
  text-align: center;
}
@media screen and (max-width: 1040px) {
  .notfound .homepage {
    margin: 32px 0 0 0;
  }
}
@media screen and (max-width: 1040px) {
  .notfound {
    background-position: 60% 50%;
  }
}